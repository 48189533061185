import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import ModeIcon from '@mui/icons-material/Mode';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';



export default function Row({ data }) {


    const { t } = useTranslation()


    return (
        <>
            <div style={{marginBottom:10}}>


                <TableContainer component={Box} sx={{backgroundColor:'white'}} >

                    <Table sx={{ worder_item_idth: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_number')}</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('web_site')}</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('domain_name')}</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_start_date')}</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("revision")}</TableCell>
                                {/* <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("amount")}</TableCell> */}
                                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_status")}</TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow
                                //   key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell align="center">{data.order_item_id}</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 14 }}>

                                    <a href={`https://${data.publicationData}`} target="_blank">{data.publicationData}</a>


                                </TableCell>
                                <TableCell align="center">{data.anchorText}</TableCell>
                                <TableCell align="center">{data.expirydate}</TableCell>
                                <TableCell align="center">{data.revision}</TableCell>
                                {/* <TableCell align="center" sx={{ color: 'primary' }}>{data.total
                  }</TableCell> */}
                                <TableCell align="center">
                                    {/* <div style={{ padding: 1, backgroundColor: '#e3f2fd' */}
                                    <div style={{
                                        padding: 3, backgroundColor:
                                            `${data.order_sts === 'pendingarticle' ? '#fff75e'
                                                // : data.order_sts === "completed" ? "#b2ff9e"
                                                    : data.order_sts === 'published' ? '#e3f2fd'
                                                        : "#f38375"}`
                                        , borderRadius: '12px'
                                    }}>

                                        {t(data.order_sts)}
                                    </div>

                                </TableCell>





                            </TableRow>

                        </TableBody>
                    </Table>

                </TableContainer>

            </div>
        </>
    );
}


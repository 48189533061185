import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useUserOrderContext } from 'context/UserOrderContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Typography } from '@mui/material';
import swal from 'sweetalert';

const triggerAlert = () => {
    swal({
        title: "Text Copied!",
        text: " ",
        icon: "success",
        timer: 1000,
        buttons: false
    })
}

const Note = React.forwardRef(({ props }, ref) => {
    const [open, setOpen] = React.useState(false);
    const { currentOrder } = useUserOrderContext()
    const { t } = useTranslation()



    // console.log(currentItem)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <div style={{ position: 'relative' }}>
            <Button variant="outlined" onClick={handleClickOpen} ref={ref} sx={{ display: 'none' }}>

            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

                
            >
                {/* <IconButton color='primary'
                    sx={{ position: 'absolute', right: 0, top: 10 }}
                    onClick={() => {
                        triggerAlert()
                        navigator.clipboard.writeText(currentItem.pro_examplepublication)
                    }}
                >
                    <ContentCopyIcon />
                </IconButton> */}


                <DialogTitle id="alert-dialog-title">
                    {t("revision_note")}
                    {/* NOTA */}
                </DialogTitle>
                <DialogContent sx={{
                    // width: '200px',
                    // margin: 0 auto;
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word'
                }}>
                    <DialogContentText id='copy'>
                        {currentOrder.description}

                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div >)
}
)
export default Note;
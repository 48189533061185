// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles'
// import TextEditor from './Source/Components/Editor/TextEditor';
import Dashboard from './Source/Screens/Dashboard/Dashbaord';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import ScreensNavigator from './Source/Screens/ScreensNavigator';
import UserAuthContextProvider, { useUserAuth } from './context/UserAuthContext';
import Login from './Source/Screens/Auth/Login';
import SignUp from './Source/Screens/Auth/Signup';
import AuthScreenNavigator from './Source/Screens/Auth/AuthScreenNavigator';
import ProtectedRoute from './Protected Routes/UserRoute';
import ForgetPassword from 'Source/Screens/Auth/ForgetPassword';
import ForgetPasswordNavigator from 'Source/Screens/Auth/ForgetPassword/ForgetPasswordNavigator';
// import {useNavigate} from 'react-router-dom'
// console.log(process.env.REACT_APP_URL)

function App() {
  const [mode, setMode] = React.useState('light');
  const getDesignTokens = (mode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
          // palette values for light mode
          primary: {
            main: '#2196f3',
            medium: '#64b5f6',
            light: '#e3f2fd'
          },
          // divider: amber[200],
          text: {
            primary: '#263238',
            secondary: '#757575',
            light: '#fff',
            white: '#fff'
          },
        }
        : {
          // palette values for dark mode
          primary: {
            main: '#757575',
            medium: '#b0bec5',
            light: "#e0e0e0",
            white: '#fff'
          },
          // divider: deepOrange[700],
          // background: {
          //   default: deepOrange[900],
          //   paper: deepOrange[900],
          // },
          text: {
            primary: '#e0e0e0',
            secondary: '#607d8b',
            light: '#263238'
          },
        }),
    },
  });

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  const { isAuthenticated } = useUserAuth()
  // const navigate=useNavigate()

  return (
    <ThemeProvider theme={theme}>
      <Router>


        <Routes>
          <Route path='/*' exact element={<ProtectedRoute Component={Dashboard} />} />
          <Route path='/login' exact element={<Login />} />
          <Route path='/signup' exact element={<SignUp />} />
          {/* <Route path='/forgotPassword' element={<ForgetPassword/>} /> */}
          <Route  path='/forgotPassword' element={<ForgetPasswordNavigator/>}  />
          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found</h2>
              </div>}
          />
        </Routes>

      </Router>
    </ThemeProvider >
  );
}

export default App;

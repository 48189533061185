import React from 'react'
import { Box } from '@mui/system'
import { Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { useUserOrderContext } from 'context/UserOrderContext'

export default function Sales() {
    const { t } = useTranslation()
    const theme = useTheme()
    const { dashboardOrders } = useUserOrderContext()
    // console.log(dashboardOrders)
    const styles = {
        paper: {
            // width: '15rem',
            height: '4rem',
            borderRadius: 3,
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        text: {
            fontSize: 12, color: theme.palette.text.secondary
        },
        box: {
            height: '3.5rem',
            width: '4rem',
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
            p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'
        }
    }
    return (
        <Grid container spacing={4} alignItems={'center'}>

            <Grid item xs={12} sm={3} md={3} lg={3} >
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('order_added')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            0
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            {dashboardOrders.orderadded === null ? '0' : dashboardOrders.orderadded}
                        </Typography>
                    </Box>

                </Paper>

            </Grid>



            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('accepted_article')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            0
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            {dashboardOrders.accepted === null ? '0' : dashboardOrders.accepted}
                        </Typography>
                    </Box>

                </Paper>
            </Grid>




            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('pending_confirmation')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            0
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            {dashboardOrders.pending === null ? '0' : dashboardOrders.pending}
                        </Typography>
                    </Box>

                </Paper>
            </Grid>




            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('mordify_orders')}
                        </Typography>
                        {/* <Typography variant='h6'>
                            0
                        </Typography> */}
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            {dashboardOrders.ordertochnage === null ? '0' : dashboardOrders.ordertochnage}
                        </Typography>
                    </Box>

                </Paper>
            </Grid>

            {/* <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('profit')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}



            {/* <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('expense')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}



            {/* 
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('tax')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}




            {/* <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper elevation={2} sx={styles.paper} >
                    <Box>

                        <Typography sx={styles.text}>
                            {t('total_sales')}
                        </Typography>
                        <Typography variant='h6'>
                            $0
                        </Typography>
                    </Box>
                    <Box sx={styles.box}>
                        <Typography align='center' sx={{ color: theme.palette.text.white }}>
                            0
                        </Typography>
                    </Box>

                </Paper>
            </Grid> */}


        </Grid>



    )
}

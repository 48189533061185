import React from 'react'
import { Box } from '@mui/system'
import CkEditor from 'Source/Components/Editor/CkEditor'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { IconButton, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import Row from './Row';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { useOrderContext } from 'context/OrderContext';
import { useUserOrderContext } from 'context/UserOrderContext';

export default function EditorScrren() {
    const data = useLocation().state.data;
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { orderResponse, setOrdersResponse, setNewOrder } = useOrderContext()
    const { userOrders, setUserOrders } = useUserOrderContext()
    const [updatedText, setUpdateText] = React.useState('')

    // const decode = new TextEncoder().decode(data.writing_text);
    // console.log(updatedText)


    // ENCODE STRING TO UFT-8
    //UPDATE ORDER LIST
    const utf8EncodedString = new TextEncoder().encode(updatedText);

    const updateOrderList = (id) => {
        setUserOrders(
            userOrders.map((item) =>
                item.order_item_id == id ? { ...item, order_sts: 'pendingarticle', writing_text: JSON.stringify(utf8EncodedString) } : item

            )
        )
    }

    // console.log(userOrders)
    if (orderResponse.is_sts === 0) {
        swal(
            {
                title: `${t("error")}`,
                text: `${orderResponse.msg}`,
                icon: 'error',

            }
        )
        setOrdersResponse('')
    }
    if (orderResponse.is_sts === 1) {
        swal(
            {
                title: `${t("order_added")}`,
                text: `${orderResponse.msg}`,
                icon: 'success',
                buttons: false,
                timer: 1000
            }
        )
        updateOrderList(data.order_item_id)
        setOrdersResponse('')
        navigate('/requestedOrders')
    }


    // console.log(data)
    return (
        <Paper elevation={2} sx={{ p: 3, paddingBottom: 10 }}>
            <IconButton color='primary' sx={{ marginBottom: 2 }}
                onClick={() => navigate('/requestedOrders')}
            >
                <KeyboardReturnIcon />
            </IconButton>


            <Row data={data} />
            <Typography variant='h6' align='center'>
                {t('edit_text')}
            </Typography>
            <CkEditor data={data} setUpdateText={setUpdateText} />
        </Paper>
    )
}

import React from 'react'
import { Box } from '@mui/system'
import Sales from './Sales'
import Income from './Income'
import Graph1 from './Data visiualization/Graph1'
// import Graph2 from './Data visiualization/Graph2'
import { useUserOrderContext } from 'context/UserOrderContext'
import Spinner from 'Source/Components/Loader/Spinner'

export default function Home() {
    const { ordersLoading } = useUserOrderContext()
    return (
    <>
    {ordersLoading ?
        <Spinner />
        : <Box >

            <Sales />
            <Graph1 />

        </Box>
    }
    </>
    )
}

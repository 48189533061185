import React from 'react'
import CkEditor from 'Source/Components/Editor/CkEditor'
import TextEditor from 'Source/Components/Editor/TextEditor'
import UpdatePassword from './UpdatePassword'

export default function Settings() {
  return (
    <div>
    <UpdatePassword/>
    </div>
  )
}

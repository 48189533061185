import React from 'react'
// import { HashRouter as BrowserRouter, Routes, Route } from 'react-router-dom'
// import History from './History'
// import Cart from './Order/Cart'
// import Home from './Home/Home'
// import Menu from './Menu'
import {
    Routes,
    Route,
} from "react-router-dom";
import Login from './Auth/Login';
import SignUp from './Auth/Signup';
import Dashboard from './Dashboard/Dashbaord';
import Home from './Home/Home';
import RequestedOrder from '../Screens/Order/RequestedOrder'
import OrderList from '../Screens/Order/OrderList'
import Settings from '../Screens/Settings/Settings'
// import Settings from './Settings'
// import Order from './Order/Order'
// import Wallet from './Wallet'
// import OrderList from './OrderList'
import { useUserAuth } from '../../context/UserAuthContext';
import Profile from './Profile/Profile';
import ProtectedRoute from '../../Protected Routes/UserRoute';
import EditorScrren from './EditorScreen/EditorScrren';


export default function ScreensNavigator() {

    const { isUserAuthenticated } = useUserAuth()


    return (
        <Routes path='/' >
            <Route index element={<Home />} />
            <Route path='requestedOrders' element={<RequestedOrder />} />
            <Route path='orderList' element={<OrderList />} />
            <Route path='profile' element={<Profile />} />
            <Route path='settings' element={<Settings />} />
            <Route path='editor' element={<EditorScrren />} />
            <Route
                path="*"
                element={
                    <div>
                        <h2>404 Page not found</h2>
                    </div>}
            />


        </Routes>

    )
}

import React from 'react'
import { Box } from '@mui/system'
import {  Grid, Paper, Typography } from '@mui/material'
import SaleChart from '../../../Components/Charts/SaleChart'
import SaleOverview from '../../../Components/Charts/SaleOverview'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Purchase from 'Source/Screens/Home/Purchase'
import ArticlePrice from '../ArticlePrice'
export default function Graph1() {

    const theme = useTheme()
    const {t}=useTranslation()
    const styles = {
        text: {
            fontSize: 12,
            color: theme.palette.text.secondary,
            textAlign: 'center',
            marginBottom: -1,
            fontWeight: 'bold'

        },
        box: {
            width: '5rem'
        }
    }
    return (
        <Grid container alignItems={'center'} spacing={5} sx={{ mt: 1 }}>

            <Grid item xs={12} sm={6} lg={6}>

                <Paper elevation={3} sx={{ height: '15rem', borderRadius: 5, p: 1 }}>
                    <Box sx={{ height: '14rem',p:1 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {t('sale')}
                        </Typography>
                        <SaleChart />

                    </Box>
                    {/* <Box sx={{ height: '4rem', mt: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>

                            <Box sx={styles.box}>
                                <Typography sx={styles.text}>Today</Typography>
                                <Typography align='center' variant='h6'>5</Typography>
                            </Box>

                            <Box sx={styles.box}>
                                <Typography sx={styles.text}>Yesterday</Typography>
                                <Typography align='center' variant='h6'>5</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>

                            <Box sx={styles.box}>
                                <Typography sx={styles.text}>This Week</Typography>
                                <Typography align='center' variant='h6'>5</Typography>
                            </Box>
                            <Box sx={styles.box}>
                                <Typography sx={styles.text}>Last Week</Typography>
                                <Typography align='center' variant='h6'>5</Typography>
                            </Box>
                        </Box>


                    </Box> */}
                </Paper>

            </Grid>


            <Grid item xs={12} sm={6} md={6} lg={3}>

                <Paper elevation={3} sx={{ height: '15rem', borderRadius: 5, p: 2 }}>
                 
                    <Purchase/>
                </Paper>

            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>

                <Paper elevation={3} sx={{ height: '15rem', borderRadius: 5, p: 2 }}>
                 
                    <ArticlePrice/>
                </Paper>

            </Grid>






        </Grid>
    )
}

import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useOrderContext } from 'context/OrderContext';


export default function CkEditor({ data, setUpdateText }) {
    // console.log(new Uint8Array(JSON.parse(data.writing_text).encoded))
    // console.log(data.writing_text)
    let initialState = "<p>Enter text here</p>"
    if (data.writing_text !== null) {

        const obj = JSON.parse(data.writing_text)
        const arr = Object.entries(obj).map(([key, value]) => value);
        initialState = new TextDecoder().decode(new Uint8Array(arr))
    }

    const [content, setContent] = React.useState(initialState)
    const [called, setCalled] = React.useState(false)
    const { t } = useTranslation()
    const { createTextOrder, isLoading } = useOrderContext()

    return (
        <Box>
            <CKEditor
                editor={ClassicEditor}

                data={content}

                onChange={(event, editor) => {
                    const data = editor.getData();
                    // console.log({ event, editor, data });
                    // console.log(data);
                    setContent(data)
                }}

                onFocus={(event, editor) => {
                    if (called) {
                        return;
                    }
                    setCalled(true)
                    data.writing_text === null && setContent('')

                }}


                config={{
                    toolbar: [
                        "heading",
                        // "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        // "ckfinder",
                        // "|",
                        // "imageTextAlternative",
                        "imageUpload",
                        // "imageStyle:full",
                        // "imageStyle:side",
                        // "|",
                        // "mediaEmbed",
                        "insertTable",
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "|",
                        "undo",
                        "redo"
                    ]

                }}
            />



            <LoadingButton
                loading={isLoading}
                disabled={!called}
                onClick={() => {
                    // console.log(content)
                    setUpdateText(content)
                    createTextOrder(content, data.order_item_id)
                }}
                sx={{ position: 'absolute', right: 50, marginTop: 2 }}
                variant='contained'>{t('submit')}</LoadingButton>

        </Box>
    )
}

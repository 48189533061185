import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import { useUserOrderContext } from 'context/UserOrderContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import Spinner from 'Source/Components/Loader/Spinner';
// import OrderDetailPopUp from 'components/PopUp/OrderDetailPopUp';
// import { IconButton } from '@mui/material';
// import { useOrderContext } from '../../../context/OrderContext';
// import { useCartContext } from 'context/CartContext';


//Creat data for row
function createData(serial, order_item_id, publicationData, anchorText, revision, expirydate, total, order_sts, pro_additionalnotes, ordertype, sito_web, destinationLink, review) {
  return { serial, order_item_id, publicationData, anchorText, revision, expirydate, total, order_sts, pro_additionalnotes, ordertype, sito_web, destinationLink, review };
}


// const userData = JSON.parse(window.localStorage.getItem('user'));

export default function RequestedOrders() {

  const { userOrders, getOrderLoading } = useUserOrderContext()
  // const { newOrder,setCurrentOrderDetails } = useOrderContext()
  // const {setCurrentOrder}=useCartContext()
  const { t } = useTranslation()

  //RELOAD API ON CHANGE ANY VALUE


  //GENERATE ROWS FOR TABLE
  const rows =
    userOrders.filter((order => order.order_sts === 'approvedarticle')).map((item, i) => {

      return createData(i, item.order_item_id
        , item.publicationData, item.anchorText, item.revision, item.expirydate,
        item.total, item.order_sts, item.pro_additionalnotes, item.ordertype, item.sito_web, item.destinationLink, item.review)

    })
  // console.log(userOrders)

  //DETAIL MODAL REF
  const detailRef = React.useRef(null)
  const handleDetailRef = () => {
    detailRef.current.click()
  }


  return (
    <>{getOrderLoading ?

      // <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      //   <Typography variant='subtitle1' align='center' sx={{ marginTop: '15rem' }}>
      //     {t('empty')}
      //   </Typography>
      // </Box>

      <Spinner />

      : <div>
        {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

          <CircularProgress sx={{ display: `${ordersLoading ? 'block' : 'none'}` }} />
        </Box> */}

        <Box sx={{ display: `${userOrders.length < 1 ? 'flex' : 'none'}`, alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant='subtitle1' align='center' sx={{ marginTop: '15rem' }}>
            {t('empty')}
          </Typography>
        </Box>


        <TableContainer component={Paper} sx={{ display: `${userOrders.length > 0 ? 'block' : 'none'}` }}>

          <Table sx={{ worder_idth: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_number')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('web_site')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('domain_name')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t('order_start_date')}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("revision")}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("web")}</TableCell>
                <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_status")}</TableCell>
                {/* <TableCell sx={{ fontWeight: '600', fontSize: 15 }} align="center">{t("order_detail")}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >

                  <TableCell align="center">{row.order_item_id}</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 14 }}>

                    <a href={`https://${row.publicationData}`} target="_blank"
                      style={{ textDecoration: 'none' }}
                    >{row.publicationData}</a>


                  </TableCell>
                  <TableCell align="center">{row.anchorText}</TableCell>
                  <TableCell align="center">{row.expirydate}</TableCell>
                  <TableCell align="center">{row.revision}</TableCell>
                  <TableCell align="center" sx={{ color: 'primary' }}>
                    <a href={`https://${row.sito_web}`} target="_blank"
                      style={{ textDecoration: 'none' }}
                    >{row.sito_web}</a>
                  </TableCell>
                  <TableCell align="center">

                    <CheckCircleIcon sx={{ color: '#66bb6a' }} />


                  </TableCell>
                  {/* <TableCell align="center">

                    <IconButton variant="contained"
                      onClick={() => {

                        handleDetailRef()
                        setCurrentOrderDetails(row)
                        console.log(row)
                      }
                      }
                    >

                      <InfoIcon color='primary' />
                    </IconButton>
                  </TableCell> */}

                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>

        {/* <OrderDetailPopUp ref={detailRef} /> */}
      </div>
    }
    </>
  );
}


import React, { useContext, createContext } from "react";
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL


const OrderContext = createContext()
export default function OrderContextProvider({ children }) {
    const [newOrder, setNewOrder] = React.useState(0)
    const [orderResponse, setOrdersResponse] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSubmited, setIsSubmited] = React.useState(false)
    const [currentOrderDetails, setCurrentOrderDetails] = React.useState('')

    React.useEffect(() => {

    }, [])



    // 1:Create New  ORDERS
    const createTextOrder = async (editorContent, order_item_id) => {

        const user = JSON.parse(localStorage.getItem('user'))


        const textEncoder = new TextEncoder();
        const utf8EncodedString = textEncoder.encode(editorContent);
        setIsLoading(true)

        // const encoder = new TextEncoder();
        // const encodedString = encoder.encode(editorContent)
        // console.log({encodedString})
        // console.log(encoder.encode(editorContent).Uint8Array)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=uploadarticle`, {
            user_id: user.user_id,
            order_id: order_item_id,
            writer_text: JSON.stringify(utf8EncodedString)

        })
            .then(function (response) {
                // console.log(response)
                // console.log(response.data)
                setOrdersResponse(response.data)
                setNewOrder(1)
                setIsLoading(false)
                // setIsSubmited(true)

            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)
                setOrdersResponse('error')

            });
    }

    //FILE UPLOAD 




    return (<OrderContext.Provider value={{
        newOrder, setNewOrder, isLoading, createTextOrder, orderResponse,
        setOrdersResponse, isSubmited, setIsSubmited, currentOrderDetails, setCurrentOrderDetails
    }}>
        {children}
    </OrderContext.Provider>)

}
export const useOrderContext = () => {
    return useContext(OrderContext)
}
import * as React from 'react';
import * as yup from "yup";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert'
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import LogoIcon from '../../../Ascets/Logos/logo.png'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useUserAuth } from '../../../context/UserAuthContext';




const theme = createTheme();

export default function SignUp() {
    const { t } = useTranslation();
    //Yup Schema
    const schema = yup.object({
        password: yup.string().trim().min(6, `${t("short_password")}`).required(`Password richiesto`),
        confirmPassword: yup.string().trim().oneOf([yup.ref('password'), null], `Prova con la stessa password`),
        email: yup.string().email(`${t("invalid_email")}`).required(`E-mail richiesto`),
        fullName: yup.string().trim().required(`${t("name_required")}`),
        phoneNo: yup.string().min(11, "Numero di telefono troppo breve").required("Numero di telefono richiesto"),
        articlePrice: yup.string().required("Prezzo per articolo richiesto")
        // country: yup.string().trim().required(`${t("country_required")}`),
        // city: yup.string().trim().required(`${t("city_required")}`),
    }).required();


    const [gender, setGender] = React.useState('');
    const { registerUser, loading, resStatus, setResStatus } = useUserAuth()
    const navigate = useNavigate()
    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit = data => {

        // registerUser({ ...data, gender })
        registerUser({ ...data })
        // console.log(data)


    }

    //gender and email section
    if (resStatus.is_sts === 1) {
        swal(
            {
                title: `${t("reg_success")}`,
                text: `${t("account_created_login")}`,
                icon: 'success',
                //  button:'ok'
            }
        )
        setResStatus('')
        navigate('/login')
    }
    if (resStatus.is_sts === 0) {
        swal(
            {
                title: `${t("reg_error")}`,
                text: `${resStatus.msg}`,
                icon: 'error',
                //  button:'ok'
            }
        )
        setResStatus('')
    }
    const handleChange = (event) => {
        setGender(event.target.value);
    };
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                    <img
                        src={LogoIcon}
                        style={{
                            width: '19rem',
                            height: '5rem',
                        }}
                    />
                </Box>
                <Paper elevation={3}
                    sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 5
                        , marginBottom: '2rem',
                        // height:'10rem'
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography variant="h5" align="center" gutterBottom>
                        {t("signup")}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)} sx={{ mt: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="fullName"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            sx={{ color: 'black' }}
                                            {...field}
                                            autoComplete="given-name"
                                            name="fullName"
                                            // required
                                            fullWidth
                                            id="fullName"
                                            label={t("full_name")}
                                            defaultValue=""
                                            error={errors.fullName}
                                            helperText={errors.fullName?.message}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="email"
                                            label={t("email")}
                                            name="email"
                                            autoComplete="email"
                                            defaultValue=""
                                            error={errors.email}
                                            helperText={errors.email?.message}
                                        />}
                                />
                            </Grid>

                            {/* <Grid item xs={12} sm={6}  lg={6} xl={6}>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            id="country"
                                            label="Country"
                                            name={t("country")}
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.country}
                                            helperText={errors.country?.message}
                                        />} />
                            </Grid> */}

                            {/* 
                            <Grid item xs={12} sm={6}  lg={6} xl={6}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            id="city"
                                            label={t("city")}
                                            name="city"
                                            autoComplete="family-name"
                                            defaultValue=""
                                            error={errors.city}
                                            helperText={errors.city?.message}
                                        />}
                                />
                            </Grid> */}

                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="password"
                                            label={t("password")}
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            defaultValue=""
                                            error={errors.password}
                                            helperText={errors.password?.message}
                                        />}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            // required
                                            {...field}
                                            fullWidth
                                            name="confirmPassword"
                                            label={t("confirmPassword")}
                                            type="password"
                                            id="confirmPassword"
                                            autoComplete="new-password"
                                            defaultValue=""
                                            error={errors.confirmPassword}
                                            helperText={errors.confirmPassword?.message}
                                        />}
                                />
                            </Grid>

                            {/* 
                            <Grid item xs={12} sm={4} lg={4} xl={4}>
                                        

                                <FormControl sx={{minWidth:{sm:100,xs:300}}}>
                                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={gender}
                                        // fullWidth
                                        label={t('gender')}
                                        onChange={handleChange}
                                        >
                                        <MenuItem value={'male'}>{t("male")}</MenuItem>
                                        <MenuItem value={'female'}>{t("female")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={7} lg={7} xl={7}>
                                <Controller
                                    name="phoneNo"
                                    control={control}
                                    sx={{ width: 200 }}
                                    render={({ field: { onChange, value } }) =>
                                        <MuiPhoneNumber defaultCountry={'us'}
                                            sx={{ marginTop: 3, width: "100%" }}
                                            // required
                                            defaultValue=""
                                            onChange={onChange} value={value}
                                            error={errors.phoneNo}
                                            helperText={errors.phoneNo?.message}
                                            options={{
                                                excludeCountries: [''],
                                                disableAreaCodes: false

                                            }}

                                        />} />
                            </Grid>
                            <Grid item xs={12} sm={5} lg={5} xl={5}>
                                <Controller
                                    name="articlePrice"
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            // required
                                            fullWidth
                                            type='number'
                                            inputProps={{ maxLength: 2 }}
                                            id="articlePrice"
                                            label={t("price_per_article")}
                                            name="articlePrice"
                                            // autoComplete="email"
                                            defaultValue=""
                                            error={errors.articlePrice}
                                            helperText={errors.articlePrice?.message}
                                            onChange={(event) => {
                                                if (event.target.value.length <= 2) {
                                                    field.onChange(event);
                                                }
                                            }}
                                        />}
                                />
                            </Grid>
                        </Grid>
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 1 }}
                        >
                            {t("signUp_btn")}
                        </LoadingButton>

                        <Box sx={{ fontSize: 14, display: 'flex', justifyContent: 'center', mt: 2 }}>

                            <Link to="/login" style={{ textDecoration: 'none' }} >
                                {t("already_have")}
                            </Link>
                        </Box>
                        {/* <Box sx={{ fontSize: 14, textAlign: 'center', marginTop: 4 }}>

                            <Link to="/landing"  >
                                {t("back_to_home")}
                            </Link>
                        </Box> */}
                    </form>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}


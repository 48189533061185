import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'
export default function ProtectedRoute({ Component }) {
    const navigate = useNavigate()
    const { isUserAuthenticated } = useUserAuth()
    React.useEffect(() => {
        if (!isUserAuthenticated) { navigate('/login') }

        // isUserAuthenticated&&navigate('/') 

        // console.log(isUserAuthenticated)
    }, [isUserAuthenticated])


    return (
        <div>{isUserAuthenticated ?
            <Component />
            : navigate('/login')

        }
        </div>
    )


}

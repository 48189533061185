import React, { useContext, createContext } from "react";
import axios from 'axios';
// const BASE_URL = "https://inposizione.thewebconcept.com"
// const BASE_URL = "https://link.inposizione.it"
const BASE_URL = process.env.REACT_APP_URL


const UserOrderContext = createContext()
export default function UserOrderContextProvider({ children }) {
    const [userOrders, setUserOrders] = React.useState([])
    const [authDone, setAuthDone] = React.useState(false)
    const [isOrderAdded, setIsOrderAdded] = React.useState(false)
    const [dashboardOrders, setDashboardOrders] = React.useState('')
    const [ordersLoading, setOrdersLoading] = React.useState(false)
    const [currentOrder, setCurrentOrder] = React.useState('')
    const [getOrderLoading, setOrderLoading] = React.useState(false)

    const userData = JSON.parse(localStorage.getItem('user'));


    React.useEffect(() => {

        if (userData !== null) {
            setAuthDone(false)

            userDashboard()
            getOrder()
        }
    }, [authDone])

    //RELOAD ORDERS ON CHANGE LOGIN OR PLACE NEW ORDER
    React.useEffect(() => {

        getOrder()
        if (userData !== null) {
            setIsOrderAdded(false)
        }
    }, [authDone, isOrderAdded])







    // GET USER ORDERS WITH DETAILS
    const getOrder = async () => {
        setOrderLoading(true)
        await axios.get(`${BASE_URL}/admin/api/index.php?type=writerorderlist&user_id=${userData.user_id}`)
            // await axios.get(`${BASE_URL}/admin/api/index.php?type=orderlist&user_id=${userData.user_id}`)
            .then(function (response) {
                // console.log(response.data)
                if (response.data !== null) {

                    setOrderLoading(false)
                    setUserOrders(response.data)
                }

            })
            .catch(function (error) {
                console.log(error);

            });
    }


    //GET DASHBOARD ORDERS
    const userDashboard = async () => {
        setOrdersLoading(true)
        await axios.post(`${BASE_URL}/admin/api/index.php?type=articledashboard&user_id=${userData.user_id}`)
            .then(function (response) {
                if (userData !== null) {
                    // console.log(response)
                    setDashboardOrders(response.data.data)
                    setOrdersLoading(false)
                }

            })
            .catch(function (error) {
                console.log(error);

            });
    }





    return (<UserOrderContext.Provider value={{
        userOrders, setUserOrders, dashboardOrders,
        userDashboard,
        setDashboardOrders, setAuthDone, setIsOrderAdded, getOrder, ordersLoading, currentOrder, setCurrentOrder,getOrderLoading
    }}>
        {children}
    </UserOrderContext.Provider>)

}
export const useUserOrderContext = () => {
    return useContext(UserOrderContext)
}